@use "@angular/material" as mat;

body {
  @include mat.core();
  font-family: Roboto, "Helvetica Neue", sans-serif;
  margin: 0;
  height: 100%;
  font-weight: 500 !important;

  @include mat.theme(
    (
      color: (
        theme-type: light,
        primary: mat.$azure-palette,
        tertiary: mat.$blue-palette,
      ),
      density: 0,
    )
  );
}

html {
  height: 100%;
}

.warn-snackbar,
.warn-snackbar .mat-mdc-snackbar-surface {
  background: rgb(250, 181, 54) !important;
  border-radius: 4px;
}

.info-snackbar,
.info-snackbar .mat-mdc-snackbar-surface {
  background-color: rgb(79, 199, 255) !important;
  border-radius: 4px;
}

.error-snackbar,
.error-snackbar .mat-mdc-snackbar-surface {
  background-color: #e64a19 !important;
  border-radius: 4px;
}

.success-snackbar,
.success-snackbar .mat-mdc-snackbar-surface {
  background: rgb(116, 221, 67) !important;
  border-radius: 4px;
}

.mat-simple-snackbar-action {
  color: white;
}

table tr:nth-child(even) {
  background-color: #dee0e3;
}

mat-table mat-row:nth-child(even) {
  background-color: #dee0e3;
}

.app-toolbar-filler {
  flex: 1 1 auto;
}
